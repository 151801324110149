
import { Vue, Component } from "vue-property-decorator";
import ImageEditor from "@/components/image-editor.vue";
import DataEditor from "@/components/helpers/data/editor-dialog.vue";
import editor from "./editor";
import draggable from "vuedraggable";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import {ListDataSource} from "@/data/List/ListDataSource";
import {Filter} from "@/cms-services/filter";
import {required} from "@/cms-services/consts";
@Component({
  mixins: [editor],
  components: { ImageEditor, DataEditor, draggable },
})
export default class ResourceInlineEditor extends Vue {
  dataSource!: ObjectDataSource;
  dialog = false;
  loaded = false;
  $message: any;
  seoItemDataSource = new ListDataSource({
    className:"ComponentSeoItem",
    config:{}
  })

  createItemFields: any = [
    {
      editor: "string",
      attrs: {
        type: "text",
        outlined: true,
        label: "Заголовок",
      },
      name: "keyword",
      validations: [required()],
    },
  ];


  createItemModel:any = {
    componentId:+this.dataSource?.id,
    keyword:""
  }

  async mounted(){
    this.seoItemDataSource.config.filter = JSON.stringify([
        new Filter("componentId", this.dataSource?.id)
    ])

    await this.seoItemDataSource.get();
    this.loaded = true;
  }
}
